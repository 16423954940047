import React, { useState, useEffect, useRef } from 'react';
import './battling.css'; // Import the CSS

const BattlingPage = ({ fetchUserData }) => {
  const [location, setLocation] = useState('');
  const [selectedMonster, setSelectedMonster] = useState('');
  const [battleLog, setBattleLog] = useState([]);
  const [autos, setAutos] = useState(250); // Assuming players start with 250 autos
  const [isFighting, setIsFighting] = useState(false);
  const isFightingRef = useRef(false); // Use useRef to track the fighting state
  const [accessibleLocations, setAccessibleLocations] = useState([]);
  const [monsters, setMonsters] = useState([]);
  const [loadingMonsters, setLoadingMonsters] = useState(false);

  // Fetch user data on component mount to get location access
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('No token found, skipping user data fetch');
        return; // Skip fetching if there's no token
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/data`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          // Determine accessible locations based on purchased boosts
          let locations = ['Surface']; // Surface is default
          if (data.boosts.dungeonAccess > 0) locations.push('Dungeon');
          if (data.boosts.hellAccess > 0) locations.push('Hell');
          if (data.boosts.skyAccess > 0) locations.push('Sky');
          if (data.boosts.heavenAccess > 0) locations.push('Heaven');

          setAccessibleLocations(locations);

          // Set the last selected location and monster if available
          if (data.lastLocation) {
            setLocation(data.lastLocation);
          }
          if (data.lastFoughtMonster) {
            setSelectedMonster(data.lastFoughtMonster);
          }
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);

  // Fetch monsters for the selected location
  useEffect(() => {
    const fetchMonsters = async () => {
      if (location) {
        const token = localStorage.getItem('token');
        if (!token) {
          console.log('No token found, skipping monster fetch');
          return; // Skip fetching if there's no token
        }

        setLoadingMonsters(true);
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/monsters?location=${location}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });

          if (response.ok) {
            const data = await response.json();
            setMonsters(data);
          } else {
            console.error('Failed to fetch monsters');
          }
        } catch (error) {
          console.error('Error fetching monsters:', error);
        } finally {
          setLoadingMonsters(false);
        }
      }
    };

    fetchMonsters();
  }, [location]);

  const handleLocationSelect = (event) => {
    setLocation(event.target.value);
    setSelectedMonster(''); // Reset selected monster when changing location
  };

  const handleMonsterSelect = (event) => {
    setSelectedMonster(event.target.value);
  };

 const startFight = async () => {
  console.log('Fight button clicked!'); // Debug log

  if (!location || !selectedMonster) {
    console.log('Location or monster not selected'); // Debug log for condition check
    alert('Please select a location and a monster to fight!');
    return;
  }

  if (autos <= 0) {
    console.log('No autos left'); // Debug log for condition check
    alert('You have no autos left. Please reset your autos.');
    return;
  }

  // Clear the battle log for a new fight against a different monster
  setBattleLog([]);

  // Set fighting state to true
  setIsFighting(true);
  isFightingRef.current = true; // Update the ref to reflect the new state

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/combat/fight`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ location, monster: selectedMonster }),
    });

    console.log('API Request response:', response); // Log the raw response

    if (!response.ok) {
      console.error('Failed to fetch battle result, status:', response.status);
      throw new Error('Failed to fetch battle result');
    }

    const result = await response.json();
    console.log('Battle result:', result); // Log the parsed result

    // Update the battle log with detailed battle information
    if (result.battleDetails) {
      setBattleLog(result.battleDetails);
    }

    // Update autos and rewards
    console.log('Updating autos, remaining:', autos - 1);
    setAutos((prevAutos) => prevAutos - 1);

    // Delay between battles (5000 ms)
    await new Promise((resolve) => setTimeout(resolve, 5000));
  } catch (error) {
    console.error('Error during combat:', error);
    setBattleLog(['Error occurred during combat.']);
  } finally {
    // Fighting complete or autos depleted
    console.log('Fighting complete or autos depleted');
    setIsFighting(false);
    isFightingRef.current = false; // Update the ref to reflect the new state

    // Save last location and monster selection for user convenience
    console.log('Saving last battle information...');
    await saveLastBattle(location, selectedMonster);

    // Refresh user data to reflect new experience and level
    console.log('Refreshing user data...');
    fetchUserData();
  }
};



  const stopFight = () => {
    console.log('Stop fight clicked');
    setIsFighting(false);
    isFightingRef.current = false; // Update the ref to stop fighting
    setBattleLog((prevLog) => [...prevLog, 'Fighting stopped.']);
  };

  const resetAutos = async () => {
    try {
      // Make the API request to reset autos
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/combat/restart-autos`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to reset autos');
      }

      setAutos(250); // Resetting to the maximum available
      setBattleLog((prevLog) => [...prevLog, 'Autos reset to 250.']);
    } catch (error) {
      console.error('Error resetting autos:', error);
      setBattleLog((prevLog) => [...prevLog, 'Error occurred while resetting autos.']);
    }
  };

  const saveLastBattle = async (location, monster) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('No token found, skipping save last battle');
        return; // Skip saving if there's no token
      }

      await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/save-last-battle`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ lastLocation: location, lastFoughtMonster: monster }),
      });
    } catch (error) {
      console.error('Error saving last battle:', error);
    }
  };

  useEffect(() => {
    // Effect to stop fighting when autos reach 0
    if (autos <= 0) {
      setIsFighting(false);
      isFightingRef.current = false; // Update the ref to stop fighting
      setBattleLog((prevLog) => [...prevLog, 'You have run out of autos.']);
    }
  }, [autos]);

  return (
    <div id="battling-page">
      <h2>Battling</h2>
      <div>
        <label htmlFor="location-select">Select a Location:</label>
        <select id="location-select" value={location} onChange={handleLocationSelect}>
          <option value="">-- Choose a Location --</option>
          {accessibleLocations.map((loc, index) => (
            <option key={index} value={loc}>{loc}</option>
          ))}
        </select>
      </div>
      {location && (
        <div>
          {loadingMonsters ? (
            <p>Loading monsters...</p>
          ) : (
            <>
              <label htmlFor="monster-select">Select a Monster:</label>
              <select id="monster-select" value={selectedMonster} onChange={handleMonsterSelect}>
                <option value="">-- Choose a Monster --</option>
                {monsters.map((monster, index) => (
                  <option key={index} value={monster.name}>{monster.name}</option>
                ))}
              </select>
            </>
          )}
        </div>
      )}
      <div className="battle-buttons">
        <button onClick={startFight} disabled={isFighting}>Start Fighting</button>
        <button onClick={stopFight} disabled={!isFighting}>Stop Fighting</button>
        <button onClick={resetAutos}>Restart Autos</button>
      </div>
      <div className="battle-log">
        <h3>Battle Log:</h3>
        {battleLog.map((log, index) => (
          <p key={index}>{log}</p>
        ))}
      </div>
    </div>
  );
};

export default BattlingPage;
