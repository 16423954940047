// /root/nexus-rpg/frontend/src/components/ClanPage.js

import React, { useState, useEffect } from 'react';
import './clan.css';

const ClanPage = ({ userData, fetchUserData }) => {
  const [clanData, setClanData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('details');
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [newAnnouncement, setNewAnnouncement] = useState('');
  const [goldContribution, setGoldContribution] = useState('');
  const [damage, setDamage] = useState('');

  useEffect(() => {
    if (userData?.clan) {
      fetchClanData();
    } else {
      setLoading(false);
    }
  }, [userData]);

  const fetchClanData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clan/details/${userData?.clan}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch clan data');
      }

      const data = await response.json();
      setClanData(data);
    } catch (error) {
      console.error('Error fetching clan data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setFeedbackMessage('');
  };

  const handleContribution = async () => {
    setIsLoadingAction(true);
    setFeedbackMessage('');
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clan/contribute`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: goldContribution }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || 'Failed to contribute');
      }

      await fetchClanData();
      setFeedbackMessage('Contribution successful');
    } catch (error) {
      setFeedbackMessage(`Error: ${error.message}`);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleUpdateAnnouncement = async () => {
    setIsLoadingAction(true);
    setFeedbackMessage('');
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clan/announcement`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ announcement: newAnnouncement }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || 'Failed to update announcement');
      }

      await fetchClanData();
      setFeedbackMessage('Announcement updated successfully');
    } catch (error) {
      setFeedbackMessage(`Error: ${error.message}`);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleUpgradeBuilding = async (buildingName) => {
    setIsLoadingAction(true);
    setFeedbackMessage('');
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clan/buildings/upgrade`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ buildingName }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || 'Failed to upgrade building');
      }

      await fetchClanData();
      setFeedbackMessage('Building upgraded successfully');
    } catch (error) {
      setFeedbackMessage(`Error: ${error.message}`);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleBorrowItem = async (itemId) => {
    setIsLoadingAction(true);
    setFeedbackMessage('');
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clan/armory/borrow`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itemId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || 'Failed to borrow item');
      }

      await fetchClanData();
      setFeedbackMessage('Item borrowed successfully');
    } catch (error) {
      setFeedbackMessage(`Error: ${error.message}`);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleReturnItem = async (itemId) => {
    setIsLoadingAction(true);
    setFeedbackMessage('');
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clan/armory/return`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ itemId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || 'Failed to return item');
      }

      await fetchClanData();
      setFeedbackMessage('Item returned successfully');
    } catch (error) {
      setFeedbackMessage(`Error: ${error.message}`);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleCreateClan = async () => {
  setIsLoadingAction(true);
  setFeedbackMessage('');
  try {
    const clanName = prompt('Enter Clan Name:');
    if (!clanName) {
      throw new Error('Clan name is required');
    }

    const token = localStorage.getItem('token');
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clan/create`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: clanName }),
    });

    if (!response.ok) {
      const responseText = await response.text(); // Attempt to get the response text for debugging
      if (response.headers.get('content-type')?.includes('application/json')) {
        const errorData = JSON.parse(responseText);
        throw new Error(errorData.msg || 'Failed to create clan');
      } else {
        throw new Error(`Unexpected response: ${responseText}`);
      }
    }

    await fetchUserData();
    setFeedbackMessage('Clan created successfully');
  } catch (error) {
    setFeedbackMessage(`Error: ${error.message}`);
  } finally {
    setIsLoadingAction(false);
  }
};


  const handleAttackBoss = async () => {
    setIsLoadingAction(true);
    setFeedbackMessage('');
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clan/boss/attack`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ damage }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || 'Failed to attack boss');
      }

      const responseData = await response.json();
      setFeedbackMessage('Boss attacked successfully');
      setClanData({ ...clanData, bossHP: responseData.bossHP });

    } catch (error) {
      setFeedbackMessage(`Error: ${error.message}`);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleActivateTactic = async (tacticName, duration) => {
    setIsLoadingAction(true);
    setFeedbackMessage('');
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clan/tactic/activate`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tacticName, duration }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || 'Failed to activate tactic');
      }

      await fetchClanData();
      setFeedbackMessage(`${tacticName} activated for ${duration} hours`);
    } catch (error) {
      setFeedbackMessage(`Error: ${error.message}`);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const renderTabContent = () => {
    if (!userData?.clan) {
      return (
        <div>
          <h2>No Clan</h2>
          <button onClick={handleCreateClan} disabled={isLoadingAction}>
            {isLoadingAction ? 'Creating...' : 'Create Clan'}
          </button>
        </div>
      );
    }

    switch (activeTab) {
      case 'details':
        return (
          <div>
            <h2>Clan Details</h2>
            <p>Clan Name: {clanData.name}</p>
            <p>Level: {clanData.level}</p>
            <p>Members: {clanData.members.length}</p>
            <h3>Members:</h3>
            <ul>
              {clanData.members.map(member => (
                <li key={member.userId._id}>
                  {member.userId.name} (Level: {member.userId.level}) - {member.rank}
                </li>
              ))}
            </ul>
            <h3>Announcements:</h3>
            <p>{clanData.announcements}</p>
            {(userData?.clan.rank === 'Leader' || userData?.clan.rank === 'Officer') && (
              <>
                <textarea
                  value={newAnnouncement}
                  onChange={(e) => setNewAnnouncement(e.target.value)}
                  placeholder="Update announcement..."
                />
                <button onClick={handleUpdateAnnouncement} disabled={isLoadingAction}>
                  {isLoadingAction ? 'Updating...' : 'Update Announcement'}
                </button>
              </>
            )}
          </div>
        );

      case 'buildings':
        return (
          <div>
            <h2>Buildings</h2>
            {clanData.buildings.map(building => (
              <div key={building.buildingName}>
                <h3>{building.buildingName}</h3>
                <p>Level: {building.level}</p>
                <button
                  onClick={() => handleUpgradeBuilding(building.buildingName)}
                  disabled={isLoadingAction}
                >
                  {isLoadingAction ? 'Upgrading...' : 'Upgrade'}
                </button>
              </div>
            ))}
          </div>
        );

      case 'contribute':
        return (
          <div>
            <h2>Contribute to Clan</h2>
            <input
              type="number"
              value={goldContribution}
              onChange={(e) => setGoldContribution(e.target.value)}
              placeholder="Enter gold amount"
            />
            <button onClick={handleContribution} disabled={isLoadingAction}>
              {isLoadingAction ? 'Contributing...' : 'Contribute'}
            </button>
          </div>
        );

      case 'armory':
        return (
          <div>
            <h2>Clan Armory</h2>
            {clanData.armory.map(item => (
              <div key={item.itemId}>
                <p>Item: {item.itemId}</p>
                <p>Amount: {item.amount}</p>
                {item.borrowedBy ? (
                  <p>
                    Borrowed By: {item.borrowedBy}
                    {item.borrowedBy === userData._id && (
                      <button
                        onClick={() => handleReturnItem(item.itemId)}
                        disabled={isLoadingAction}
                      >
                        {isLoadingAction ? 'Returning...' : 'Return'}
                      </button>
                    )}
                  </p>
                ) : (
                  <button
                    onClick={() => handleBorrowItem(item.itemId)}
                    disabled={isLoadingAction}
                  >
                    {isLoadingAction ? 'Borrowing...' : 'Borrow'}
                  </button>
                )}
              </div>
            ))}
          </div>
        );

      case 'logs':
        return (
          <div>
            <h2>Clan Logs</h2>
            <ul>
              {clanData.logs.map((log, index) => (
                <li key={index}>
                  <p>Action: {log.action}</p>
                  <p>Member: {log.memberName}</p>
                  <p>Details: {log.details}</p>
                  <p>Timestamp: {new Date(log.timestamp).toLocaleString()}</p>
                </li>
              ))}
            </ul>
          </div>
        );

      case 'boss':
        return (
          <div>
            <h2>Clan Boss</h2>
            {clanData.activeBoss ? (
              <div>
                <h3>Boss Name: {clanData.activeBoss.name}</h3>
                <p>HP: {clanData.bossHP} / {clanData.activeBoss.maxHP}</p>
                <input
                  type="number"
                  value={damage}
                  onChange={(e) => setDamage(e.target.value)}
                  placeholder="Enter attack damage"
                />
                <button onClick={handleAttackBoss} disabled={isLoadingAction}>
                  {isLoadingAction ? 'Attacking...' : 'Attack'}
                </button>
              </div>
            ) : (
              <p>No active boss at the moment</p>
            )}
          </div>
        );

      case 'events':
        return (
          <div>
            <h2>Clan Events</h2>
            {clanData.events && clanData.events.length > 0 ? (
              <div>
                {clanData.events.map(event => (
                  <div key={event._id}>
                    <h3>{event.name}</h3>
                    <p>Type: {event.type}</p>
                    <p>Objective: {event.objective}</p>
                    <p>Progress: {event.progress} / {event.objective}</p>
                    {event.active ? <p>Status: Ongoing</p> : <p>Status: Completed</p>}
                  </div>
                ))}
              </div>
            ) : (
              <p>No active events at the moment</p>
            )}
          </div>
        );

      case 'upgrades':
        return (
          <div>
            <h2>Clan Upgrades</h2>
            {clanData.upgrades && (
              <div>
                <div>
                  <h3>Boss Damage Boost</h3>
                  <p>Level: {clanData.upgrades.bossDamageBoost.level}</p>
                  <p>Bonus: {clanData.upgrades.bossDamageBoost.bonusPercent}%</p>
                  <button onClick={() => handleUpgradeBuilding('bossDamageBoost')} disabled={isLoadingAction}>
                    {isLoadingAction ? 'Upgrading...' : 'Upgrade'}
                  </button>
                </div>
                <div>
                  <h3>Gathering Speed Boost</h3>
                  <p>Level: {clanData.upgrades.gatheringSpeedBoost.level}</p>
                  <p>Bonus: {clanData.upgrades.gatheringSpeedBoost.bonusPercent}%</p>
                  <button onClick={() => handleUpgradeBuilding('gatheringSpeedBoost')} disabled={isLoadingAction}>
                    {isLoadingAction ? 'Upgrading...' : 'Upgrade'}
                  </button>
                </div>
                <div>
                  <h3>Experience Boost</h3>
                  <p>Level: {clanData.upgrades.experienceBoost.level}</p>
                  <p>Bonus: {clanData.upgrades.experienceBoost.bonusPercent}%</p>
                  <button onClick={() => handleUpgradeBuilding('experienceBoost')} disabled={isLoadingAction}>
                    {isLoadingAction ? 'Upgrading...' : 'Upgrade'}
                  </button>
                </div>
              </div>
            )}
          </div>
        );

      case 'tactics':
        return (
          <div>
            <h2>Clan Tactics</h2>
            <div>
              <h3>Combat Boost</h3>
              {clanData.activeTactics.combatBoost.isActive ? (
                <p>Active until: {new Date(clanData.activeTactics.combatBoost.expiresAt).toLocaleString()}</p>
              ) : (
                <button onClick={() => handleActivateTactic('combatBoost', 1)} disabled={isLoadingAction}>
                  {isLoadingAction ? 'Activating...' : 'Activate for 1 hour'}
                </button>
              )}
            </div>
            <div>
              <h3>Gathering Boost</h3>
              {clanData.activeTactics.gatheringBoost.isActive ? (
                <p>Active until: {new Date(clanData.activeTactics.gatheringBoost.expiresAt).toLocaleString()}</p>
              ) : (
                <button onClick={() => handleActivateTactic('gatheringBoost', 1)} disabled={isLoadingAction}>
                  {isLoadingAction ? 'Activating...' : 'Activate for 1 hour'}
                </button>
              )}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  if (loading) {
    return <div>Loading Clan Data...</div>;
  }

  return (
    <div className="clan-page">
      {/* Tabs Navigation */}
      <div className="clan-tabs">
        <button onClick={() => handleTabChange('details')}>Details</button>
        <button onClick={() => handleTabChange('buildings')}>Buildings</button>
        <button onClick={() => handleTabChange('contribute')}>Contribute</button>
        <button onClick={() => handleTabChange('armory')}>Armory</button>
        <button onClick={() => handleTabChange('logs')}>Logs</button>
        <button onClick={() => handleTabChange('boss')}>Boss</button>
        <button onClick={() => handleTabChange('events')}>Events</button>
        <button onClick={() => handleTabChange('upgrades')}>Upgrades</button>
        <button onClick={() => handleTabChange('tactics')}>Tactics</button>
      </div>

      {/* Clan Content */}
      <div className="clan-content">
        {feedbackMessage && <p className="feedback-message">{feedbackMessage}</p>}
        {renderTabContent()}
      </div>
    </div>
  );
};

export default ClanPage;
