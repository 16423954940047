import React, { createContext, useState, useEffect, useCallback } from 'react';

const AuthContext = createContext(); // Define the AuthContext

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ token: null, isAuthenticated: false });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuth({ token, isAuthenticated: true });
    }
    setLoading(false); // Set loading to false once the token is retrieved
  }, []);

  const login = useCallback((token) => {
    localStorage.setItem('token', token);
    setAuth({ token, isAuthenticated: true });
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setAuth({ token: null, isAuthenticated: false });
  }, []);

  return (
    <AuthContext.Provider value={{ auth, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext; // Export the AuthContext as default
