import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import './login.css';

const AuthPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const { token } = useParams();

  // Effect to handle account confirmation
  useEffect(() => {
    if (token) {
      const confirmAccount = async () => {
        try {
          const response = await fetch(`https://nxrpg.com/api/confirm/${token}`);
          const data = await response.json();
          if (response.ok) {
            setConfirmationMessage('Account confirmed successfully! You can now log in.');
          } else {
            setErrorMessage(data.message || 'Account confirmation failed.');
          }
        } catch (err) {
          setErrorMessage('An error occurred while confirming your account.');
        }
      };
      confirmAccount();
    }
  }, [token]);

// Login submission handler
const handleLoginSubmit = async (e) => {
  e.preventDefault();
  try {
    const response = await fetch('https://nxrpg.com/api/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();
    if (response.ok) {
      login(data.token);
      navigate('/news'); // Navigate to NewsPage after successful login
    } else {
      setErrorMessage(data.message || 'Login failed. Please try again.');
    }
  } catch (err) {
    setErrorMessage('An error occurred. Please try again later.');
  }
};

  // Register submission handler
const handleRegisterSubmit = async (e) => {
  e.preventDefault();

  // Clear any previous messages before starting
  setErrorMessage('');
  setConfirmationMessage('');

  if (email !== confirmEmail) {
    setErrorMessage('Emails do not match.');
    return;
  }

  if (password !== confirmPassword) {
    setErrorMessage('Passwords do not match.');
    return;
  }

  try {
    const response = await fetch('https://nxrpg.com/api/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, email, password }),
    });

    const data = await response.json();
    if (response.ok) {
      // Clear error messages and show the success message
      setErrorMessage('');
      setConfirmationMessage('Registration successful! Please check your email to confirm your account.');
    } else {
      setErrorMessage(data.message || 'Registration failed. Please try again.');
    }
  } catch (err) {
    setErrorMessage('An error occurred. Please try again later.');
  }
};


  return (
    <div>
      <div id="header">
        {/* Assuming you already have a header.png in the header div */}
        <img src="/path/to/header.png" alt="Header" />
      </div>

      {/* Message section */}
      <div id="message-box" style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
        {errorMessage && <div className="error-message" style={{ color: 'red' }}>{errorMessage}</div>}
        {confirmationMessage && <div className="confirmation-message" style={{ color: 'green' }}>{confirmationMessage}</div>}
      </div>

      <div id="page">
        <div id="content-wrapper">
          <div id="left-holder">
            {/* Login Form */}
            <div id="login-box" style={{ marginBottom: '20px' }}>
              <h1>Login</h1>
              <p>If you have an account, please login below.</p>

              <form id="loginForm" onSubmit={handleLoginSubmit}>
                <table cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr>
                      <td style={{ width: '75px' }}>Username:</td>
                      <td>
                        <input
                          name="username"
                          placeholder="Username..."
                          required
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Password:</td>
                      <td>
                        <input
                          type="password"
                          name="password"
                          placeholder="Password..."
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <button type="submit" name="login">Login</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>

<div id="forgot-links" style={{ marginTop: '15px' }}>
  <p style={{ marginTop: '15px' }}>
    <span 
      onClick={() => navigate('/forgot-username-or-password')} 
      style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
    >
      Forgot Username or Password?
    </span>
  </p>
</div>
</div>


            {/* Register Form */}
            <div id="register-box" style={{ marginTop: '20px' }}>
              <h1>Register</h1>
              <p>Create an account below.</p>

              <form id="registerForm" onSubmit={handleRegisterSubmit}>
                <table>
                  <tbody>
                    <tr>
                      <td>Username:</td>
                      <td>
                        <input
                          type="text"
                          name="username"
                          placeholder="Username..."
                          required
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Email:</td>
                      <td>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email..."
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Confirm Email:</td>
                      <td>
                        <input
                          type="email"
                          name="confirmEmail"
                          placeholder="Confirm Email..."
                          required
                          onChange={(e) => setConfirmEmail(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Password:</td>
                      <td>
                        <input
                          type="password"
                          name="password"
                          placeholder="Password..."
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Confirm Password:</td>
                      <td>
                        <input
                          type="password"
                          name="password2"
                          placeholder="Confirm Password..."
                          required
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <button type="submit">Register</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>

          <div id="right-holder">
            {/* Your statistics and features sections */}
            <div className="stats-box">
              <h1 style={{ textAlign: 'center' }}>Game Statistics</h1>
              <table style={{ margin: '0 auto', textAlign: 'center' }}>
                <tbody>
                  <tr>
                    <td>Players Online:</td>
                    <td>124</td>
                  </tr>
                  <tr>
                    <td>Monsters Defeated:</td>
                    <td>54</td>
                  </tr>
                  <tr>
                    <td>Bosses Alive:</td>
                    <td>23</td>
                  </tr>
                  <tr>
                    <td>Bosses Defeated:</td>
                    <td>12,345</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="features-box" style={{ marginTop: '25px' }}>
              <h1 style={{ textAlign: 'center' }}>Features</h1>
              <ul style={{ textAlign: 'center' }}>
                <li>Hundreds of monsters...</li>
                <li>Defeat powerful Heroes...</li>
                <li>Join with other players to kill Bosses that spawn in Rifts...</li>
                <li>Join a clan and build clan buildings to provide bonuses...</li>
                <li>Craft weapons and armor... socket them with jewels...</li>
                <li>Gather resources to help your clan build buildings...</li>
                <li>Play as much as you want...</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <footer id="footer">
        <p>Nexus RPG &copy; 2024</p>
      </footer>
    </div>
  );
};

export default AuthPage;
