import React, { useState } from 'react';

const GiveItemModal = ({ onClose, onGive, otherPlayerName }) => {
  const [amount, setAmount] = useState('');
  const [item, setItem] = useState('');

  const handleGive = () => {
    if (amount && item) {
      onGive(item, amount);
      onClose();
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Give Item to {otherPlayerName}</h2>
        <label>Item:</label>
        <input type="text" value={item} onChange={(e) => setItem(e.target.value)} />
        <label>Amount:</label>
        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
        <button onClick={handleGive}>Give</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default GiveItemModal;
