import React, { useEffect, useState } from 'react';
import './news.css';

const NewsPage = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate fetching news from an API
    const fetchNews = async () => {
      setLoading(true);
      const fetchedNews = [
        {
          title: 'Welcome to Nexus RPG!',
          content: 'We are thrilled to have you join our community! Enjoy your adventure!',
          date: 'October 18, 2024'
        },
        {
          title: 'New Update Released',
          content: 'We have added new monsters and a brand-new crafting system! Be sure to check it out!',
          date: 'October 12, 2024'
        },
      ];
      setNews(fetchedNews);
      setLoading(false);
    };

    fetchNews();
  }, []);

  return (
    <div id="news-page">
      <h1>Latest News</h1>
      {loading ? (
        <p>Loading news...</p>
      ) : (
        <>
          {news.length === 0 ? (
            <p>Stay tuned for upcoming news!</p>
          ) : (
            <div className="news-container">
              {news.map((item, index) => (
                <div key={index} className="news-item">
                  <h2>{item.title}</h2>
                  <p>{item.content}</p>
                  <span>{item.date}</span>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NewsPage;
