// App.js
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { UserProvider } from './context/UserContext'; // Import UserProvider
import AuthPage from './components/AuthPage';
import GamePage from './components/GamePage';
import ResetPassword from './components/ResetPassword';
import ForgotUsernameOrPassword from './components/ForgotUsernameOrPassword'; // Import the combined component
import AuthContext, { AuthProvider } from './context/AuthContext';

const App = () => {
  return (
    <AuthProvider>
      <UserProvider> {/* Wrap the application with UserProvider */}
        <Router>
          <Routes>
            <Route path="/" element={<AuthPage />} />
            <Route path="/confirm/:token" element={<AuthPage />} />
            <Route path="/forgot-username-or-password" element={<ForgotUsernameOrPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route
              path="/game"
              element={
                <PrivateRoute>
                  <GamePage />
                </PrivateRoute>
              }
            />
            {/* Redirect any other route to /game */}
            <Route path="*" element={<Navigate to="/game" />} />
          </Routes>
        </Router>
      </UserProvider>
    </AuthProvider>
  );
};

// Private Route Component to protect routes
const PrivateRoute = ({ children }) => {
  const { auth } = useContext(AuthContext);

  if (!auth.isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
};

export default App;
