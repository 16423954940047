import React, { useEffect, useState } from 'react';
import axios from 'axios';

const InventoryPage = () => {
  const [items, setItems] = useState([]);
  const [activeSection, setActiveSection] = useState('overview');

useEffect(() => {
  // Fetch inventory items on page load
  const fetchItems = async () => {
    try {
      const response = await axios.get('/api/inventory', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Include auth token
        },
      });
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching inventory:', error);
    }
  };

  fetchItems();
}, []);


  const handleNavigation = (section) => {
    setActiveSection(section);
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'inventory':
        return (
          <div>
            <h2>Inventory</h2>
            <p>Here you can find all your resources, fragments, and chests.</p>
            <ul>
              {items
                .filter(item => item.type === 'resource')
                .map(item => (
                  <li key={item._id}>{item.name}: {item.quantity}</li>
                ))}
            </ul>
          </div>
        );
      case 'equipment':
        return (
          <div>
            <h2>Equipment</h2>
            <p>Check all your equipped and unequipped weapons, shields, helms, gloves, boots, leggings, and armors here.</p>
            <ul>
              {items
                .filter(item => item.type === 'equipment')
                .map(item => (
                  <li key={item._id}>
                    {item.name} - (Level {item.level})<br />
                    Strength: {item.stats.strength} | Agility: {item.stats.agility} | Defense: {item.stats.defense}<br />
                    Accuracy: {item.combatStats.accuracy} | Evasion: {item.combatStats.evasion} | Damage: {item.combatStats.damage}
                  </li>
                ))}
            </ul>
          </div>
        );
      case 'accessories':
        return (
          <div>
            <h2>Accessories</h2>
            <p>Manage your rings, trinkets, amulets, and cape here.</p>
            <ul>
              {items
                .filter(item => item.type === 'accessory')
                .map(item => (
                  <li key={item._id}>
                    {item.name} - (Level {item.level})<br />
                    Luck: {item.stats.luck} | Critical Chance: {item.combatStats.criticalChance} | Gold Boost: {item.boosts.goldBoost}
                  </li>
                ))}
            </ul>
          </div>
        );
      default:
        return (
          <div>
            <h2>Inventory Overview</h2>
            <p>Welcome to your inventory! Here you can find different sections to manage your items:</p>
            <ul>
              <li><strong>Inventory:</strong> View your collected resources, fragments, and chests.</li>
              <li><strong>Equipment:</strong> Manage your weapons, shields, helms, gloves, boots, leggings, and armors.</li>
              <li><strong>Accessories:</strong> Check out your rings, trinkets, amulets, and cape.</li>
            </ul>
          </div>
        );
    }
  };

  return (
    <div id="inventory-page">
      <div className="inventory-navigation">
        <button onClick={() => handleNavigation('inventory')}>Inventory</button>
        <button onClick={() => handleNavigation('equipment')}>Equipment</button>
        <button onClick={() => handleNavigation('accessories')}>Accessories</button>
      </div>
      <div className="inventory-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default InventoryPage;
