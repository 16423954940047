// /root/nexus-rpg/frontend/src/components/LeaderboardPage.js

import React, { useState, useEffect } from 'react';
import './leaderboard.css';

const LeaderboardPage = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [metric, setMetric] = useState('contributions');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10); // Change this value to control number of items per page

  useEffect(() => {
    fetchLeaderboardData(metric, currentPage);
  }, [metric, currentPage]);

  const fetchLeaderboardData = async (metric, page) => {
    setLoading(true);
    setError('');
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/leaderboard/${metric}?page=${page}&limit=${itemsPerPage}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || 'Failed to fetch leaderboard data. Please try again later.');
      }

      const data = await response.json();
      setLeaderboardData(data.leaderboard);
      setTotalPages(data.totalPages);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMetricChange = (newMetric) => {
    if (!loading) {
      setMetric(newMetric);
      setCurrentPage(1); // Reset to first page when metric changes
    }
  };

  const handlePageChange = (direction) => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    } else if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <div className="leaderboard-page">
      <h2>Clan Leaderboard</h2>
      <div className="leaderboard-metrics">
        <button onClick={() => handleMetricChange('contributions')} disabled={loading}>
          Total Contributions
        </button>
        <button onClick={() => handleMetricChange('bossesDefeated')} disabled={loading}>
          Bosses Defeated
        </button>
        <button onClick={() => handleMetricChange('eventProgress')} disabled={loading}>
          Event Progress
        </button>
      </div>

      {loading ? (
        <div>Loading Leaderboard...</div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : (
        <>
          <table className="leaderboard-table">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Clan Name</th>
                <th>Level</th>
                <th>Members</th>
                {metric === 'contributions' && <th>Total Contribution</th>}
                {metric === 'bossesDefeated' && <th>Bosses Defeated</th>}
                {metric === 'eventProgress' && <th>Event Progress</th>}
              </tr>
            </thead>
            <tbody>
              {leaderboardData.map((clan, index) => (
                <tr key={clan._id}>
                  <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                  <td>{clan.name}</td>
                  <td>{clan.level}</td>
                  <td>{clan.members.length}</td>
                  {metric === 'contributions' && <td>{clan.totalContribution}</td>}
                  {metric === 'bossesDefeated' && <td>{clan.bossesDefeated}</td>}
                  {metric === 'eventProgress' && <td>{clan.totalEventProgress}</td>}
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination-controls">
            <button onClick={() => handlePageChange('prev')} disabled={currentPage === 1 || loading}>
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button onClick={() => handlePageChange('next')} disabled={currentPage === totalPages || loading}>
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default LeaderboardPage;
