import React, { useState, useEffect, useContext, useRef } from 'react';
import './chat.css';
import { io } from 'socket.io-client';
import { UserContext } from '../context/UserContext';
import { capitalizeFirstLetter } from '../utils/formatters';

const ChatBox = () => {
  const [activeChannel, setActiveChannel] = useState('Main');
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const { user, isAuthenticated, loading } = useContext(UserContext);
  const { name: username, gameRank, purchaseRank, role, clan } = user || {};
  const messagesEndRef = useRef(null);
  const socketRef = useRef(null);

  const channels = [
    'Main', 'Trade', 'Help', 'Recruiting', 'Clan', 'Private', 'Log', 'Statistics'
  ];

  useEffect(() => {
    if (loading || !isAuthenticated) return;

    // Initialize socket if not already initialized
    if (!socketRef.current) {
      console.log("Initializing socket connection...");
      socketRef.current = io('https://nxrpg.com', { transports: ['websocket'] });

      // Register username with server when connecting
      if (username) {
        socketRef.current.emit('registerUsername', username);
      }

      // Join all channels
      channels.forEach((channel) => {
        socketRef.current.emit('joinChannel', channel);
      });

      // Handle incoming chat messages
      const handleChatMessage = (msg) => {
        setMessages((prevMessages) => [...prevMessages, msg]);
        scrollToBottom();
      };

      socketRef.current.on('chatMessage', handleChatMessage);

      // Cleanup on component unmount or when socket reconnects
      return () => {
        if (socketRef.current) {
          socketRef.current.off('chatMessage', handleChatMessage);
          socketRef.current.disconnect();
          socketRef.current = null; // Clean up the socket reference
        }
      };
    }
  }, [isAuthenticated, loading, username]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const sendMessage = () => {
  if (input.trim() === '') return;

  if (input.startsWith('/')) {
    const [command, targetUsername, ...rest] = input.slice(1).split(' ');
    const messageContent = rest.join(' ');

    if (command === 'm') {
      // Handle private message
      if (targetUsername && messageContent) {
        const privateMessage = {
          toUsername: targetUsername,
          fromUsername: capitalizeFirstLetter(username),
          content: messageContent,
        };
        socketRef.current.emit('privateMessage', privateMessage);
      } else {
        console.log('Invalid private message command format.');
      }

      setInput('');
      return;
    }

    if (['mute', 'ban', 'kick'].includes(command)) {
      if (['Mod', 'Admin', 'Owner'].includes(role)) {
        switch (command) {
          case 'mute':
            socketRef.current.emit('muteUser', { targetUsername });
            break;
          case 'ban':
            socketRef.current.emit('banUser', { targetUsername });
            break;
          case 'kick':
            socketRef.current.emit('kickUser', { targetUsername });
            break;
          default:
            console.log('Unknown command');
        }
      } else {
        console.log('You do not have permission to use this command.');
      }

      setInput('');
      return;
    }
  }

  if (activeChannel !== 'Log' && activeChannel !== 'Statistics') {
    if (activeChannel === 'Clan' && !clan) {
      console.log('You are not in a clan.');
      return;
    }

    const newMessage = {
      username: capitalizeFirstLetter(username),
      gameRank,
      purchaseRank,
      content: input,
      channel: activeChannel,
    };

    socketRef.current.emit('chatMessage', newMessage);
    setInput(''); // Clear input field
  }
};


  const handleUsernameClick = (clickedUsername) => {
    setInput(`/m ${clickedUsername}: `);
  };

  const handleUsernameDoubleClick = (clickedUsername) => {
    window.location.href = `/player/${clickedUsername}`;
  };

  const handleChannelChange = (channel) => {
    setActiveChannel(channel);
  };

  useEffect(() => {
    console.log('User Data:', user);
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div id="chat-box">
      {/* Chat Channel Tabs */}
      <div id="chat-channels">
        {channels.map((channel) => (
          <button
            key={channel}
            className={channel === activeChannel ? 'active-channel' : ''}
            onClick={() => handleChannelChange(channel)}
          >
            {channel}
          </button>
        ))}
      </div>

      {/* Chat Messages */}
      <div id="chat-messages">
        {messages
          .filter((msg) => msg.channel === activeChannel)
          .map((msg, index) => (
            <div key={index} className="chat-message">
              <span
                className="chat-username"
                onClick={() => handleUsernameClick(msg.username)}
                onDoubleClick={() => handleUsernameDoubleClick(msg.username)}
              >
                {/* Always display purchase rank */}
                {msg.purchaseRank} {capitalizeFirstLetter(msg.username)}{' '}
                {/* Display admin rank if it is not 'Player' */}
                {msg.gameRank && msg.gameRank !== 'Player' && `(${msg.gameRank})`}:
              </span>{' '}
              {msg.content}
            </div>
          ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Box */}
      <div id="chat-input">
        <input
          type="text"
          placeholder={`Type a message in ${activeChannel}...`}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') sendMessage();
          }}
          disabled={
            activeChannel === 'Log' ||
            activeChannel === 'Statistics' ||
            (activeChannel === 'Clan' && !clan)
          }
        />
        <button
          onClick={sendMessage}
          disabled={
            activeChannel === 'Log' ||
            activeChannel === 'Statistics' ||
            (activeChannel === 'Clan' && !clan)
          }
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatBox;
