import React, { useEffect, useState } from 'react';

const Boosts = ({ fetchUserData }) => {
  const [activeSection, setActiveSection] = useState('purchase');
  const [userData, setUserData] = useState({});
  const [boostLimits, setBoostLimits] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/data`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserData(data);
          setBoostLimits(data.boostLimits); // Set boost limits from the user data
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, [fetchUserData]);

  const handlePurchaseBoost = async (boostType) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/boosts/purchase`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ boostType })
      });

      if (response.ok) {
        console.log(`${boostType} purchased successfully`);
        // Refresh user data to reflect the new boosts
        fetchUserData();
      } else {
        const errorData = await response.json();
        console.error('Failed to purchase boost:', errorData.message);
      }
    } catch (error) {
      console.error('Error purchasing boost:', error);
    }
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'purchase':
        return (
          <div>
            <h2>Purchase Credits</h2>
            <p>Total Spent: ${userData.boosts?.totalSpent || 0}</p>
            <p>Current Rank: {userData.purchaseRank || 'Peasant'}</p>
            {/* PayPal integration to be added here */}
          </div>
        );
      case 'battling':
        return (
          <div>
            <h2>Battling Boosts</h2>
            <ul>
              {renderBoostItem('Gold Boost', 'goldBoost', 200, 10)}
              {renderBoostItem('Combat Exp Boost', 'combatExpBoost', 200, 10)}
              {renderBoostItem('Stat Drop Boost', 'statDropBoost', 200, 10)}
              {renderBoostItem('Health Boost', 'healthBoost', 200, 10)}
              {renderBoostItem('Damage Boost', 'damageBoost', 200, 10)}
              {renderBoostItem('Defense Boost', 'defenseBoost', 200, 10)}
              {renderBoostItem('Accuracy Boost', 'accuracyBoost', 200, 10)}
              {renderBoostItem('Evasion Boost', 'evasionBoost', 200, 10)}
              {renderBoostItem('Critical Chance Boost', 'criticalChanceBoost', 200, 10)}
              {renderBoostItem('Critical Damage Boost', 'criticalDamageBoost', 200, 10)}
              {renderBoostItem('Damage Reduction Boost', 'damageReductionBoost', 200, 10)}
              {renderBoostItem('Boss Damage Boost', 'bossDamageBoost', 100, 10)}
              {renderBoostItem('Boss Defense Boost', 'bossDefenseBoost', 100, 10)}
            </ul>
          </div>
        );
      case 'gathering':
        return (
          <div>
            <h2>Gathering Boosts</h2>
            <ul>
              {renderBoostItem('Gathering Exp Boost', 'gatheringExpBoost', 200, 10)}
              {renderBoostItem('Mining Power', 'miningPower', 200, 10)}
              {renderBoostItem('Woodcutting Power', 'woodcuttingPower', 200, 10)}
              {renderBoostItem('Hunting Power', 'huntingPower', 200, 10)}
              {renderBoostItem('Prospecting Power', 'prospectingPower', 200, 10)}
            </ul>
          </div>
        );
      case 'autos':
        return (
          <div>
            <h2>Autos</h2>
            <ul>
              {renderBoostItem('Buy Autos', 'autos', Infinity, 1, true)}
            </ul>
          </div>
        );
      case 'global':
        return (
          <div>
            <h2>Global Boosts</h2>
            <ul>
              {renderBoostItem('Global Drop', 'globalDrop', 100, 10)}
              {renderBoostItem('Quest Action Reduction', 'questActionReduction', 100, 10)}
              {renderBoostItem('Dungeon Access', 'dungeonAccess', 1, 10, true)}
              {renderBoostItem('Sky Access', 'skyAccess', 1, 10, true)}
              {renderBoostItem('Hell Access', 'hellAccess', 1, 10, true)}
              {renderBoostItem('Heaven Access', 'heavenAccess', 1, 10, true)}
            </ul>
          </div>
        );
      default:
        return null;
    }
  };

  const renderBoostItem = (label, boostType, max, cost, binary = false) => {
    const currentBoost = userData.boosts?.[boostType] || 0;
    const maxBoost = boostLimits?.[boostType] || max;

    return (
      <li key={boostType}>
        {label} (Max: {maxBoost}) - Cost: {cost} Credits each - You have a total of {currentBoost} out of {maxBoost}
        <button onClick={() => handlePurchaseBoost(boostType)}>Purchase</button>
      </li>
    );
  };

  return (
    <div id="boosts-page">
      <div className="boosts-navigation">
        <button onClick={() => setActiveSection('purchase')}>Purchase</button>
        <button onClick={() => setActiveSection('battling')}>Battling</button>
        <button onClick={() => setActiveSection('gathering')}>Gathering</button>
        <button onClick={() => setActiveSection('autos')}>Autos</button>
        <button onClick={() => setActiveSection('global')}>Global</button>
      </div>
      <div className="boosts-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default Boosts;
