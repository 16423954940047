import React, { useContext, useEffect, useState } from 'react';
import './game.css';
import AuthContext from '../context/AuthContext';
import { UserContext } from '../context/UserContext';
import ChatBox from './ChatBox';
import NewsPage from './NewsPage';
import GiveItemModal from './GiveItemModal';
import { formatNumberWithDots, capitalizeFirstLetter } from '../utils/formatters';
import Boosts from './Boosts';
import InventoryPage from './InventoryPage';
import BattlingPage from './BattlingPage'; // Import BattlingPage Component
import ClanPage from './ClanPage';
import LeaderboardPage from './LeaderboardPage';

const GamePage = () => {
  const { logout } = useContext(AuthContext);
  const { user } = useContext(UserContext);
  const [userData, setUserData] = useState(null);
  const [activeSection, setActiveSection] = useState('news');
  const [viewingOtherPlayer, setViewingOtherPlayer] = useState(false);
  const [otherPlayerData, setOtherPlayerData] = useState(null);
  const [showGiveItemModal, setShowGiveItemModal] = useState(false);
  const [loading, setLoading] = useState(true); // Added loading state

  // Fetch user data from server
  const fetchUserData = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      console.log('No token found, skipping user data fetch');
      setLoading(false); // Stop loading if there's no token
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/data`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const data = await response.json();
      setUserData(data); // Set the user data from the response
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false); // Stop loading after attempt
    }
  };

  // Fetch user data when component mounts
  useEffect(() => {
    fetchUserData();
  }, []);

const handleNavigation = (section) => {
  setActiveSection(section);
  if (['home', 'leaderboard'].includes(section)) {
    fetchUserData();
  }
};


const handleGiveItem = (item, amount) => {
  console.log(`Giving ${amount} of ${item} to ${otherPlayerData.name}`);
};


  const handleLogout = () => {
    logout();
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  const formattedUsername = capitalizeFirstLetter(userData.name);
  const formattedGold = formatNumberWithDots(userData.gold);
  const formattedCredits = formatNumberWithDots(userData.credits);
  const formattedLevel = formatNumberWithDots(userData.level);

const renderContent = () => {
  switch (activeSection) {
    case 'news':
      return <NewsPage />;

    case 'boosts':
      return <Boosts fetchUserData={fetchUserData} />;

    case 'battling':
      return <BattlingPage fetchUserData={fetchUserData} />;

    case 'inventory':
      return <InventoryPage fetchUserData={fetchUserData} />;

case 'clan':
  return <ClanPage fetchUserData={fetchUserData} />;

case 'leaderboard':
  return <LeaderboardPage fetchUserData={fetchUserData} />;

    default:
      if (viewingOtherPlayer && otherPlayerData) {
        return (
          <div id="game-content-wrapper">
            <h1 style={{ textAlign: 'center' }}>Hello {capitalizeFirstLetter(otherPlayerData.name)}</h1>
            <div className="info-box">
              <h2>Player Information</h2>
              <p>Username: {capitalizeFirstLetter(otherPlayerData.name)}</p>
              <p>Level: {formatNumberWithDots(otherPlayerData.level)}</p>
              <p>Exp until next level: {formatNumberWithDots(otherPlayerData.expUntilNextLevel || 0)}</p>
              <p>Age: {otherPlayerData.ageInDays || 0} days</p>
              <p>Rank: {otherPlayerData.rank || 'None'}</p>
              <h3>Mining Level: {otherPlayerData.gathering?.miningLevel || 0}</h3>
              <h3>Woodcutting Level: {otherPlayerData.gathering?.woodcuttingLevel || 0}</h3>
              <h3>Hunting Level: {otherPlayerData.gathering?.huntingLevel || 0}</h3>
              <h3>Prospecting Level: {otherPlayerData.gathering?.prospectingLevel || 0}</h3>
            </div>
            <div className="info-box">
              <h2>Clan Information</h2>
              <p>Clan Name: {otherPlayerData.clan?.name || 'No Clan'}</p>
              <p>Rank: {otherPlayerData.clan?.rank || 'None'}</p>
              <p>Clan Members: {otherPlayerData.clan?.members || 0} / {otherPlayerData.clan?.maxMembers || 0}</p>
              <p>Clan Level: {otherPlayerData.clan?.level || 0}</p>
              <p>Clan Bosses Defeated: {otherPlayerData.clan?.bossesDefeated || 0}</p>
            </div>
            <div className="info-box">
              <h2>Player Stats</h2>
              <p>Strength: {formatNumberWithDots(otherPlayerData.stats?.strength || 0)}</p>
              <p>Agility: {formatNumberWithDots(otherPlayerData.stats?.agility || 0)}</p>
              <p>Defense: {formatNumberWithDots(otherPlayerData.stats?.defense || 0)}</p>
              <p>Dexterity: {formatNumberWithDots(otherPlayerData.stats?.dexterity || 0)}</p>
              <p>Health: {formatNumberWithDots(otherPlayerData.stats?.health || 0)}</p>
              <p>Accuracy: {otherPlayerData.combatStats?.accuracy || '0%'}</p>
              <p>Evasion: {otherPlayerData.combatStats?.evasion || '0%'}</p>
              <p>Critical Chance: {otherPlayerData.combatStats?.criticalChance || '0%'}</p>
              <p>Critical Damage: {otherPlayerData.combatStats?.criticalDamage || '0%'}</p>
              <p>Damage: {formatNumberWithDots(otherPlayerData.combatStats?.damage || 0)}</p>
              <p>Luck: {formatNumberWithDots(otherPlayerData.stats?.luck || 0)}</p>
              <p>Armor: {formatNumberWithDots(otherPlayerData.combatStats?.armor || 0)}</p>
              <p>Constitution: {formatNumberWithDots(otherPlayerData.combatStats?.constitution || 0)}</p>
              <p>Speed: {formatNumberWithDots(otherPlayerData.combatStats?.speed || 0)}</p>
            </div>
            <div className="info-box">
              <h2>Statistics</h2>
              <p>Total Battles: {formatNumberWithDots(otherPlayerData.stats?.totalBattles || 0)}</p>
              <p>Battles Won: {formatNumberWithDots(otherPlayerData.stats?.battlesWon || 0)}</p>
              <p>Battles Lost: {formatNumberWithDots(otherPlayerData.stats?.battlesLost || 0)}</p>
              <p>Bosses Defeated: {formatNumberWithDots(otherPlayerData.stats?.bossesDefeated || 0)}</p>
              <p>Monsters Defeated: {formatNumberWithDots(otherPlayerData.stats?.monstersDefeated || 0)}</p>
              <p>Mined: {formatNumberWithDots(otherPlayerData.gathering?.mined || 0)} times</p>
              <p>Woodcutted: {formatNumberWithDots(otherPlayerData.gathering?.woodcutted || 0)} times</p>
              <p>Hunted: {formatNumberWithDots(otherPlayerData.gathering?.hunted || 0)} times</p>
              <p>Prospected: {formatNumberWithDots(otherPlayerData.gathering?.prospected || 0)} times</p>
              <p>Crafted: {formatNumberWithDots(otherPlayerData.stats?.crafted || 0)} times</p>
              <p>Quests Completed: {formatNumberWithDots(otherPlayerData.stats?.questsCompleted || 0)}</p>
            </div>
            <button onClick={() => setShowGiveItemModal(true)}>Give Item</button>
            {showGiveItemModal && (
              <GiveItemModal
                otherPlayerName={otherPlayerData.name}
                onClose={() => setShowGiveItemModal(false)}
                onGive={handleGiveItem}  // This is the important line that connects the modal to the function
              />
            )}
          </div>
        );
      }

 // Regular profile content if viewing own profile
 return (
  <div id="game-content-wrapper">
    <h1 style={{ textAlign: 'center' }}>Hello {capitalizeFirstLetter(userData.name)}</h1>
    <div className="info-box">
      <h2>Player Information</h2>
      <p>Username: {capitalizeFirstLetter(userData.name)}</p>
      <p>Level: {formatNumberWithDots(userData.level)}</p>
      <p>Exp until next level: {formatNumberWithDots(userData.expUntilNextLevel || 0)}</p>
      <p>Age: {userData.ageInDays || 0} days</p>
      <p>Rank: {userData.purchaseRank || 'Peasant'}</p>
      <h3>Mining Level: {userData.gathering?.miningLevel || 0}</h3>
      <h3>Woodcutting Level: {userData.gathering?.woodcuttingLevel || 0}</h3>
      <h3>Hunting Level: {userData.gathering?.huntingLevel || 0}</h3>
      <h3>Prospecting Level: {userData.gathering?.prospectingLevel || 0}</h3>
    </div>
    <div className="info-box">
  <h2>Clan Information</h2>
  <p>Clan Name: {userData.clan?.name || 'No Clan'}</p>
  <p>Rank: {
    // Assuming `rank` is an attribute of the user within the clan members
    userData.clan?.members?.find(member => member.userId === userData._id)?.rank || 'None'
  }</p>
  <p>Clan Members: {userData.clan?.members?.length || 0} / {userData.clan?.maxMembers || 0}</p>
  <p>Clan Level: {userData.clan?.level || 0}</p>
  <p>Clan Bosses Defeated: {userData.clan?.bossesDefeated || 0}</p>
</div>

    <div className="info-box">
      <h2>Player Stats</h2>
      <p>Strength: {formatNumberWithDots(userData.stats?.strength || 0)}</p>
      <p>Agility: {formatNumberWithDots(userData.stats?.agility || 0)}</p>
      <p>Defense: {formatNumberWithDots(userData.stats?.defense || 0)}</p>
      <p>Dexterity: {formatNumberWithDots(userData.stats?.dexterity || 0)}</p>
      <p>Health: {formatNumberWithDots(userData.stats?.health || 0)}</p>
      <p>Accuracy: {userData.combatStats?.accuracy || '0%'}</p>
      <p>Evasion: {userData.combatStats?.evasion || '0%'}</p>
      <p>Critical Chance: {userData.combatStats?.criticalChance || '0%'}</p>
      <p>Critical Damage: {userData.combatStats?.criticalDamage || '0%'}</p>
      <p>Damage: {formatNumberWithDots(userData.combatStats?.damage || 0)}</p>
      <p>Luck: {formatNumberWithDots(userData.stats?.luck || 0)}</p>
      <p>Armor: {formatNumberWithDots(userData.combatStats?.armor || 0)}</p>
      <p>Constitution: {formatNumberWithDots(userData.combatStats?.constitution || 0)}</p>
      <p>Speed: {formatNumberWithDots(userData.combatStats?.speed || 0)}</p>
    </div>
    <div className="info-box">
      <h2>Statistics</h2>
      <p>Total Battles: {formatNumberWithDots(userData.stats?.totalBattles || 0)}</p>
      <p>Battles Won: {formatNumberWithDots(userData.stats?.battlesWon || 0)}</p>
      <p>Battles Lost: {formatNumberWithDots(userData.stats?.battlesLost || 0)}</p>
      <p>Bosses Defeated: {formatNumberWithDots(userData.stats?.bossesDefeated || 0)}</p>
      <p>Monsters Defeated: {formatNumberWithDots(userData.stats?.monstersDefeated || 0)}</p>
      <p>Mined: {formatNumberWithDots(userData.gathering?.mined || 0)} times</p>
      <p>Woodcutted: {formatNumberWithDots(userData.gathering?.woodcutted || 0)} times</p>
      <p>Hunted: {formatNumberWithDots(userData.gathering?.hunted || 0)} times</p>
      <p>Prospected: {formatNumberWithDots(userData.gathering?.prospected || 0)} times</p>
      <p>Crafted: {formatNumberWithDots(userData.stats?.crafted || 0)} times</p>
      <p>Quests Completed: {formatNumberWithDots(userData.stats?.questsCompleted || 0)}</p>
    </div>
  </div>
  );
  }
};


// The return for the GamePage component itself should be placed AFTER the renderContent function
return (
  <div id="game-wrapper">
    {/* Header Section */}
    <div id="game-header">
      {/* Header Links */}
      <div id="header-links">
        <span onClick={handleLogout} style={{ cursor: 'pointer' }}>Logout</span>
        <span onClick={() => setActiveSection('achievements')}>Achievements</span>
        <span onClick={() => setActiveSection('referrals')}>Referrals</span>
        <span onClick={() => setActiveSection('rules')}>Rules</span>
        <span onClick={() => setActiveSection('settings')}>Settings</span>
        <span onClick={() => setActiveSection('leaderboard')}>Leaderboards</span>
        <span onClick={() => setActiveSection('playersOnline')}>Players Online</span>
        <span onClick={() => setActiveSection('news')}>News</span>
        <span onClick={() => setActiveSection('wiki')}>Wiki</span>
      </div>
    </div>

    {/* Top Navigation Section */}
    <div id="top-navigation">
      <button onClick={() => handleNavigation('home')}>Home</button>
      <button onClick={() => setActiveSection('inventory')}>Inventory</button>
      <button onClick={() => setActiveSection('battling')}>Battling</button>
      <button onClick={() => setActiveSection('crafting')}>Crafting</button>
      <button onClick={() => setActiveSection('gathering')}>Gathering</button>
      <button onClick={() => setActiveSection('clan')}>Clan</button>
      <button onClick={() => setActiveSection('market')}>Market</button>
      <button onClick={() => setActiveSection('events')}>Events</button>
      <button onClick={() => setActiveSection('land')}>Land</button>
      <button onClick={() => setActiveSection('boosts')}>Boosts</button>
      <button onClick={() => setActiveSection('pets')}>Pets</button>
      <button onClick={() => setActiveSection('wilderness')}>Wilderness</button>
    </div>

      {/* Game Layout Section */}
      <div id="game-layout">
        {/* Left Sidebar */}
        <div id="left-sidebar">
          <div className="sidebar-box">
            <h2 className="box-title">{formattedUsername}</h2>
            <p>Level: {formattedLevel}</p>
            <p>Gold: {formattedGold}</p>
            <p>Credits: {formattedCredits}</p>
            <p>Iron: {formatNumberWithDots(userData.resources?.iron || 0)}</p>
            <p>Copper: {formatNumberWithDots(userData.resources?.copper || 0)}</p>
            <p>Steel: {formatNumberWithDots(userData.resources?.steel || 0)}</p>
            <p>Oak: {formatNumberWithDots(userData.resources?.oak || 0)}</p>
            <p>Pine: {formatNumberWithDots(userData.resources?.pine || 0)}</p>
            <p>Birch: {formatNumberWithDots(userData.resources?.birch || 0)}</p>
            <p>Animal Skins: {formatNumberWithDots(userData.resources?.animalSkins || 0)}</p>
            <p>Meat: {formatNumberWithDots(userData.resources?.meat || 0)}</p>
            <p>Bones: {formatNumberWithDots(userData.resources?.bones || 0)}</p>
            <p>Sapphire: {formatNumberWithDots(userData.resources?.sapphire || 0)}</p>
            <p>Ruby: {formatNumberWithDots(userData.resources?.ruby || 0)}</p>
            <p>Diamond: {formatNumberWithDots(userData.resources?.diamond || 0)}</p>
          </div>

          <div className="sidebar-box">
            <h2 className="box-title">Stats</h2>
            <p>Strength: {formatNumberWithDots(userData.stats?.strength || 0)}</p>
            <p>Agility: {formatNumberWithDots(userData.stats?.agility || 0)}</p>
            <p>Defense: {formatNumberWithDots(userData.stats?.defense || 0)}</p>
            <p>Dexterity: {formatNumberWithDots(userData.stats?.dexterity || 0)}</p>
            <p>Health: {formatNumberWithDots(userData.stats?.health || 0)}</p>
            <p>Luck: {formatNumberWithDots(userData.stats?.luck || 0)}</p>
          </div>

          <div className="sidebar-box">
            <h2 className="box-title">Combat Stats</h2>
            <p>Accuracy: {userData.combatStats?.accuracy || '0%'}</p>
            <p>Evasion: {userData.combatStats?.evasion || '0%'}</p>
            <p>Critical Chance: {userData.combatStats?.criticalChance || '0%'}</p>
            <p>Critical Damage: {userData.combatStats?.criticalDamage || '0%'}</p>
            <p>Damage: {formatNumberWithDots(userData.combatStats?.damage || 0)}</p>
            <p>Armor: {formatNumberWithDots(userData.combatStats?.armor || 0)}</p>
            <p>Constitution: {formatNumberWithDots(userData.combatStats?.constitution || 0)}</p>
            <p>Speed: {formatNumberWithDots(userData.combatStats?.speed || 0)}</p>
          </div>

          <div className="sidebar-box">
            <h3 className="box-title">Personal Quests</h3>
            <p>Combat: Kill 137 monsters (120 Left)</p>
            <p>Gathering: Gather 200 resources (180 Left)</p>
            <h3 className="box-title">Community Quests</h3>
            <p>Combat: Kill 500 monsters (488 Left)</p>
            <p>Gathering: Gather 1000 resources (1354 Left)</p>
          </div>
        </div>

        {/* Center Game Page */}
        <div id="game-page">
          {renderContent()}
        </div>

        {/* Right Sidebar */}
        <div id="right-sidebar">
          <div className="sidebar-box">
            <h2 className="box-title">Equipment</h2>
            <p>Uncommon - Iron Sword - (5)</p>
            <p>Uncommon - Steel Sword - (3)</p>
            <p>Uncommon - Leather Armor - (4)</p>
            <p>Uncommon - Iron Helm - (2)</p>
            <p>Uncommon - Leather Leggings - (3)</p>
            <p>Uncommon - Leather Boots - (3)</p>
            <p>Uncommon - Leather Gloves - (3)</p>
            <p>Uncommon - Silver Ring - (1)</p>
            <p>Uncommon - Bronze Necklace - (2)</p>
            <p>Uncommon - Cloth Cape - (1)</p>
            <p>Uncommon - Diamond Trinket - (4)</p>
          </div>

          <div className="sidebar-box">
            <h2 className="box-title">Bosses</h2>
            <p>There are currently 5 bosses alive!</p>
          </div>

          <div className="sidebar-box">
            <h2 className="box-title">Current Action</h2>
            <p>You killed the Goblin Warrior</p>
            <p>You gained 50 Gold and 20 XP</p>
          </div>
        </div>
      </div>

      {/* ChatBox Component */}
      <ChatBox />

      {/* Footer */}
      <footer id="game-footer">
        <p>Nexus RPG &copy; 2024</p>
      </footer>
    </div>
  );
};

export default GamePage;