// utils/formatters.js

// Function to format numbers with dots for thousands
const formatNumberWithDots = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

// Function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

module.exports = {
  formatNumberWithDots,
  capitalizeFirstLetter,
};
